import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import { FaSpinner } from "react-icons/fa"

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Storylava" />
    <p
      className="fade-out-now mt-4 w-100 text-center"
      style={{ paddingTop: 40 }}
    >
      <FaSpinner size={30} color="#666666" className="icon-spin" />
    </p>
    <h2 className="fade-in-now w-100 text-center" style={{ opacity: 0 }}>
      Not found
    </h2>
    <p
      className="fade-in-now mb-4 w-100 text-center"
      style={{ opacity: 0, paddingBottom: 40 }}
    >
      Uh oh, we couldn't load this page.
    </p>
  </Layout>
)
export default NotFoundPage
